
import { contactSupport } from "@toppick/common/build/api/support";
import {
  validateSupportDescriptionMax,
  validateSupportDescriptionMin,
  validateSupportSubject,
} from "@toppick/common/build/validators";
import Loader from "@/components/ui/Loader.vue";
import { SupportSubject } from "@toppick/common/build/interfaces";
import { defineComponent } from "@vue/runtime-core";
import Multiselect from "@vueform/multiselect";
import { getErrorMessage } from "@toppick/common/build/utils";
import "./styles.css";
import { getAuthToken } from "@/utils/auth";

export default defineComponent({
  components: { Multiselect, Loader },
  data() {
    return {
      selectedSubject: "",
      description: "",
      error: "",
      isSuccess: false,
      isLoading: false,
      isPageLoading: true,
      emailSentSvg: require("@/assets/images/email-sent.svg"),
    };
  },

  methods: {
    validateForm() {
      if (!validateSupportSubject(this.selectedSubject)) {
        throw new Error("Subject is required");
      }
      if (!validateSupportDescriptionMin(this.description)) {
        throw new Error("Description too short");
      }
      if (!validateSupportDescriptionMax(this.description)) {
        throw new Error("Description too long");
      }
    },
    async onSubmit() {
      this.isLoading = true;
      this.error = "";

      try {
        this.validateForm();
        await contactSupport(
          await getAuthToken(),
          this.selectedSubject as SupportSubject,
          this.description
        );
        this.isSuccess = true;
      } catch (error) {
        console.log("eee", error, getErrorMessage(error));
        this.error = getErrorMessage(error);
      }
      this.isLoading = false;
    },
  },

  computed: {
    subjects() {
      return Object.keys(SupportSubject).map((key) => ({
        value: SupportSubject[key],
        label: SupportSubject[key],
      }));
    },
    placeholderDescription(): string {
      if (this.selectedSubject) {
        return this.selectedSubject;
      } else {
        return "Select a Subject";
      }
    },
  },
  mounted() {
    this.isPageLoading = false;
  },
});
